<template>
  <div>
    <h2>Downloads</h2>
    <br>
    <h2>rp_scp_tophat - <em>Old Source 1 version (unfinished)</em></h2>
    <div class="card">
      <p>I promised I'd make the <code>.bsp</code> and <code>.vmf</code> files available to the public, given that we are no longer working on this map.</p>
      <p>So, here they are. If you want more information on what <strong>rp_scp_tophat</strong> you can find that in the Source 1 section of the site.</p>
      <div class="files">
        <a v-for="file in getFiles('rp_scp_tophat')" :key="file" class="file" :href="file.src">
          <div style="margin: 8px 8px;">{{ file.label }}</div>
        </a>
      </div>
      <p><em>NOTE: This requires custom textures... and I misplaced them... sorry. When I find them I'll add them</em></p>
    </div>
    <h2>TopHat Community Modpack - Season one world</h2>
    <div class="card">
      <p>Season one was abruptly ended when Adventurous accidentally burnt the world... No, seriously. He corrupted the damn map with a flamethrower...</p>
      <p>This download should contain the original map, now free of any corruptions, <em>I think</em>, but feel free to let me know if there is any issues.</p>
      <div class="files">
        <a v-for="file in getFiles('tophat_mc_season1')" :key="file" class="file" :href="file.src">
          <div style="margin: 8px 8px;">{{ file.label }}</div>
        </a>
      </div>
    </div>
    <h2>ovo_pit</h2>
    <div class="card">
      <p>The original gmod version of OvO featured a dev textured map known as "Pit", a recreation of the COD: WWII 1v1 Pit.</p>
      <p>It's featured in the workshop, but I guess if someone wants the source files then here they are.</p>
      <div class="files">
        <a v-for="file in getFiles('ovo_pit')" :key="file" class="file" :href="file.src">
          <div style="margin: 8px 8px;">{{ file.label }}</div>
        </a>
      </div>
    </div>
    <h2>rp_downtown_remastered</h2>
    <div class="card">
      <p>A community I was a part of wanted a remake of <code>rp_downtown_tits_v2</code>, I created this as a proof of concept to submit to them.</p>
      <p>They decided not to choose me, so I didn't continue development. I might return in the future, but if you want access you can find the source files on my github below.</p>
      <p><a href="https://github.com/WhiskeeDev/rp_downtown_remastered">https://github.com/WhiskeeDev/rp_downtown_remastered</a></p>
      <p><em>NOTE: This requires custom textures... and I misplaced them... sorry. When I find them I'll add them to the github</em></p>
    </div>
    <image-preview v-model="previewSrc" :type="previewType" />
  </div>
</template>
<script>
import ImagePreview from '@/components/ImagePreview'

export default {
  components: { ImagePreview },
  data () {
    return {
      previewSrc: null,
      previewType: 'video',
      downloads: {
        'rp_scp_tophat': {
          items: [
            'rp_scp_tophat_v2.bsp',
            'rp_scp_tophat_v2.vmf',
            'rp_scp_tophat_v2.vmx'
          ]
        },
        'tophat_mc_season1': {
          items: [
            'world.ss9P.tar'
          ]
        },
        'ovo_pit': {
          items: [
            'ovo_pit.zip'
          ]
        }
      }
    }
  },
  methods: {
    getFiles (id) {
      if (!id) return null

      let files = []
      this.downloads[id].items.forEach(item => {
        console.log(item)
        files.push({
          src: `https://assets.whiskee.me/${item}`,
          label: item
        })
      })

      return files
    },
    download (file) {
      window.open(file.src)
    }
  }
}
</script>
